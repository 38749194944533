import styled from "styled-components"

const ChainAccountSummaryStyle = styled.div`
    border: 1px solid pink;
    padding: 10px;
`

interface ChainAccountSummaryProp {
    address: string
    balance: Number
}

function ChainAccountSummary({address, balance}: ChainAccountSummaryProp) {
    return (
        <ChainAccountSummaryStyle className="chain-account-summary">
            {`Address: ${address}, Balance: ${balance.toFixed(4)}`}
        </ChainAccountSummaryStyle>
    )
}

export default ChainAccountSummary
