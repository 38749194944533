import { useEffect, useState } from "react";
import { IAccount, IListAccountsResp } from "../models/IListAccountsResp";
import { AxiosResponse } from 'axios';
import PortfolioSummary from "./PortfolioSummary";
import ChainSummary from "./ChainSummary";
import { getBackendHost } from "../utils";
import styled from "styled-components";


const axios = require('axios').default;
const BACKEND_SERVER_URL = getBackendHost()

const DashboardStyle = styled.div`
    border: 1px solid red;
    padding: 20px 5px 20px;
`

function Dashboard() {
    const [ accounts, setAccounts ] = useState<IAccount[]>()
    const [ portfolioTotal, setPortfolioTotal ] = useState<number>(0)

    async function populateData() {
        const resp: AxiosResponse<IListAccountsResp> = await axios.get(`${BACKEND_SERVER_URL}/api/accounts`)
        const respAccounts: IAccount[] = resp.data?.accounts
        setAccounts(respAccounts)

        // Sum portfolio
        const total: number = respAccounts.map((respAccount: IAccount) => respAccount.balance)
            .reduce((a, b) => a + b, 0)
        setPortfolioTotal(total)
    }

    useEffect(() => {
        populateData()
    }, [])


    return (
        <DashboardStyle className="dashboard">
            <PortfolioSummary
                totalAmount={portfolioTotal}
            />

            <ChainSummary
                chainName="Ethereum"
                accounts={accounts}
            />
        </DashboardStyle>
    )
}

export default Dashboard;
