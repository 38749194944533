import styled from "styled-components"


const PortfolioSummaryStyle = styled.div`
    border: 1px solid purple;
    padding: 10px;
`

interface PortfolioSummaryProp {
    totalAmount: number
}

function PortfolioSummary({totalAmount}: PortfolioSummaryProp) {

    return (
        <PortfolioSummaryStyle className="portfolio-summary">
            Gm! Your total portfolio is Ξ{totalAmount.toFixed(4)}!
        </PortfolioSummaryStyle>
    )
}

export default PortfolioSummary
