
export const hosts: Record<string, string> = {
    development: "http://127.0.0.1:8000",
    production: "https://seaport.xvam.org",
}

export function getBackendHost() {
    const env = process.env.NODE_ENV.toString()
    return hosts[env]
}
