import styled from 'styled-components'

const ChainSummaryHeaderStyle = styled.div`
    border: 1px solid black;
    padding: 10px;
`

interface ChainSummaryHeaderProp {
    chainName: string
}

function ChainSummaryHeader({chainName}: ChainSummaryHeaderProp) {

    return (
        <ChainSummaryHeaderStyle className="chain-summary-header">
            Chain: {chainName}
        </ChainSummaryHeaderStyle>
    )
}

export default ChainSummaryHeader
