import styled from "styled-components";
import { IAccount } from "../models/IListAccountsResp";
import ChainAccountSummary from "./ChainAccountSummary";
import ChainSummaryHeader from "./ChainSummaryHeader";

const ChainSummaryStyle = styled.div`
    border: 1px solid pink;
    padding: 10px;
`

interface ChainSummaryProp {
    chainName: string
    accounts: IAccount[] | undefined
}

function ChainSummary({chainName, accounts}: ChainSummaryProp) {

    function getAccounts() {
        return accounts?.map(account => {
            return (
                <ChainAccountSummary
                    key={account.address}
                    address={account.address}
                    balance={account.balance}
                />
            )
        })
    }

    return (
        <ChainSummaryStyle className="chain-summary">
            <ChainSummaryHeader
                chainName={chainName}
            />
            {getAccounts()}
        </ChainSummaryStyle>
    )
}

export default ChainSummary;
